@use "../../variables" as var;

.container {
  width: 100%;
  height: 100%;
  flex-direction: column;


  @include var.title-text;
}
