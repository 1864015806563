@use "../../variables" as var;

.container {
  width: 100%;
  margin: 0;
  padding: 20px 60px;

  justify-content: space-between;
  align-items: center;

  position: sticky;
  top: 0;
  background: white;
  z-index: 2;
  user-select: none;
}

.links {
  font-size: 16px;
  font-weight: 500;
  text-transform: uppercase;

  gap: 20px;
  color: #bababa;
  cursor: pointer;
  :hover {
    color: #000;
  }
}


@media screen and (max-width: 768px) {
  .container {
    position: relative;
    padding: 20px;
  }
}