@use "../../variables" as var;

.container {
  width: 100%;
  height: calc(100vh - 76px);
  margin: 0;
  padding: 20px 20px;

  justify-content: center;
  align-items: center;

  position: sticky;
  top: 0;
  background: white;
  z-index: 2;
  user-select: none;

  background-image: url("../../assets/image/sky.png");
  background-position: bottom;
  background-size: contain;
  background-repeat: no-repeat;
}



@media screen and (max-width: 768px) {
  .container {
    position: relative;
  }
}
