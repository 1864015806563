$mobile-break-point: 768px;

@mixin title-text {
  font-size: 80px;
  font-weight: 700;
  @include respond(mobile) {
    @include title-text-mobile;
  }
}

@mixin title-text-mobile {
  font-size: 60px;
}


@mixin h1-text {
  font-size: 48px;
  font-weight: 700;
  @include respond(mobile) {
    @include h1-text-mobile;
  }
}

@mixin h1-text-mobile {
  font-size: 32px;
}

@mixin h2-text {
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 2rem;
  @include respond(mobile) {
    @include h2-text-mobile;
  }
}

@mixin h2-text-mobile {
  font-size: 1rem;
  line-height: 1.2rem;
}

@mixin detail-text {
  font-size: 1rem;
  @include respond(mobile) {
    @include detail-text-mobile;
  }
}

@mixin detail-text-mobile {
  font-size: 0.8rem;
}

@mixin desc-text {
  font-family: 'Poppins';
  font-size: 16px;
  line-height: 20px;
  color: #bababa;
}

@mixin respond($breakpoint) {
  @if $breakpoint==mobile {
    @media only screen and (max-width: $mobile-break-point) {
      @content;
    }
  }
}
