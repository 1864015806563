@use "../../variables" as var;

.container {
  width: 100vw;
  height: auto;

  font-family: "ProximaNova", "Segoe UI", "Roboto", "Roboto Mono", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;

}
