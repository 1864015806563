@font-face {
  font-family: 'ProximaNova';
  src: url('./fonts/proxima-nova/proximanova_light.otf') format('opentype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'ProximaNova';
  src: url('./fonts/proxima-nova/proximanova_regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'ProximaNova';
  src: url('./fonts/proxima-nova/proximanova_bold.otf') format('opentype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'ProximaNova';
  src: url('./fonts/proxima-nova/proximanova_extrabold.otf') format('opentype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'ProximaNova';
  src: url('./fonts/proxima-nova/proximanova_black.otf') format('opentype');
  font-weight: 700;
  font-style: normal;
}

body {
  margin: 0;
  font-family: "Inter", "Rubik Doodle Shadow" "ProximaNova", "Segoe UI", "Roboto", "Roboto Mono", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  overflow-x: hidden;
}
